import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`You like rocks, dry trails, riding close to the sea, tasty meat and a
friendly and relaxed culture? Then you should go to Spain!`}</p>
    <br />
    <Link className="text-black" to="/destination/spain/ainsa" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/ainsa_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Aínsa`}</h2>
        <h4>{`Trail paradise at the foothills of the Pyrenees`}</h4>
        <p>{`Aínsa is one of the most popular mtb destination in Spain. The Zona Zero is
well known for its enduro tracks since the EWS took place in 2015. More than
20 official enduro routes surround the medieval village.`}</p>
        <PhoneImage src="destinations/ainsa_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain/benasque" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/benasque_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Benasque`}</h2>
        <h4>{`Gem in the Pyrenees`}</h4>
        <p>{`Benasque - in the north of Spain - is the starting point for many hikers to
climb the 3404m high Pico de Aneto, the highest peak of the Pyrenees.
Benasque is also one of the best mtb destinations with 18 marked enduro loops.`}</p>
        <PhoneImage src="destinations/benasque_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain/granada" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/granada_8.jpg" mdxType="DesktopRightImage" />
        <h2>{`Granada`}</h2>
        <h4>{`Trail biking in the Sierra Nevada`}</h4>
        <p>{`The warm climate allows mountain biking all year long at the foothills of the
over 3400m high Sierra Nevada. Mellow hills with flowy trails or gnarly rocky
enduro trails will make all kinds of bikers happy.`}</p>
        <PhoneImage src="destinations/granada_8.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain/malaga" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/malaga_6.jpg" mdxType="DesktopRightImage" />
        <h2>{`Málaga`}</h2>
        <h4>{`Mountain bikers winter residence`}</h4>
        <p>{`Malaga, in the south of Andalucía offers different spots right around
the city. From downhill over enduro to flow, you will find what you want.
Take the lift in Benalmadena, the shuttle in Alhaurín, or pedal in
the Montes de Málaga.`}</p>
        <PhoneImage src="destinations/malaga_6.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain/mallorca" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/mallorca_10.jpg" mdxType="DesktopRightImage" />
        <h2>{`Mallorca`}</h2>
        <h4>{`Wild and untamed trails`}</h4>
        <p>{`Beautiful island with challenging trails. Contrast to the well served
mountain biking, you often find these days. Here you have to work and be
in love with rocks.`}</p>
        <PhoneImage src="destinations/mallorca_10.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/spain/ojen" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/ojen_7.jpg" mdxType="DesktopRightImage" />
        <h2>{`Ojén`}</h2>
        <h4>{`Enduro trails near Marbella`}</h4>
        <p>{`Ojén is a little Enduro gem near Marbella. The mountain village with its
white buildings is surrounded by two characteristically different sides.`}</p>
        <PhoneImage src="destinations/ojen_7.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      